'use strict';

angular.module('enervexSalesappApp').controller('TodoCtrl', function($scope, $filter, $state, $stateParams, $q, TodolistsService, TodosService, Account, User, FileUploader, _, Auth, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.attachments = [];
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.todoId = $stateParams.todoId;
	$scope.todolistId = $stateParams.todolistId;
	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	$scope.me = Auth.getCurrentUser

	if ($stateParams.mode == "edit") {
		$scope.mode = "edit"
	}
	if ($scope.mode === 'edit'){
		$scope.editForm = true;
	}
	$scope.editState = function(){
		$stateParams.mode = 'edit';
		$state.go('todo', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			todolistId: $scope.todolistId,
			todoId: $scope.todoId,
			mode: 'edit'
		}, {
			inherit: false,
			reload: true
		});
	}

	TodosService.getJob().$promise.then(function(job){
		$scope.job = job;
		$scope.members = [];
		_.each(job.members, function(member){
			$scope.members.push(member.user);
		});
	});
	$scope.todolist = TodolistsService.getTodoList();
	getTodo();
	function getTodo(){
		TodosService.getTodo().$promise.then(function(todo){
			$scope.todo = todo;
			var dueDate = new Date(todo.assignAt);
			if (todo.assignAt){
				todo.assignAt = dueDate;
				dueDate = $filter('date')(dueDate, 'dd/MM/yyyy');
			}
		});
	}
	$scope.todoChange = function (todo){
		TodolistsService.updateTodo(todo);
	}
	$scope.saveTodoChange = function(todo) {
		TodolistsService.updateTodo(todo).$promise.then(function(res){
			// $scope.mode = '';
			$state.go('todo', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				todolistId: $scope.todolistId,
				todoId: $scope.todoId,
			}, {
				inherit: false,
				reload: true
			});
			// $scope.editForm = false;
		});
	}
	$scope.cancelEdit = function(){
		$scope.mode = '';
		$state.go('todo', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				todolistId: $scope.todolistId,
				todoId: $scope.todoId,
		}, {
			reload: true,
			inherit: false
		});
		getTodo();
		$scope.editForm = false;
	}
	$scope.deleteTodo = function(todo){
		TodosService.removeTodo(todo).$promise.then(function(res){
			$state.go('todolist', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				todolistId: $scope.todolistId,
			}, {
				inherit: false,
				reload: true
			});
		});
	}
	$scope.todoComplete = function(todo){
		if (todo.status === 'open') {
			todo.status = 'closed';
		} else {
			todo.status = 'open';
		}
		TodolistsService.updateTodo(todo);
	}

	$scope.checkedEmailMembers = [];
	$scope.toggleEmailCheck = function(member){
		$scope.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.setChecks = false;
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
		console.log($scope.checkedEmailMembers)
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}

	// comments
	fetchComments();
	function fetchComments(){
		TodosService.getComments().$promise.then(function(todoComments){
			$scope.todoComments = todoComments;
		});
	}
	$scope.newComment = {};
	var uploader = $scope.uploader = new FileUploader({});
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue,function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.todo.visibility);
				promises.push(TodosService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				TodosService.createComment(newComment).$promise.then(function(res) {
					fetchComments();
					$scope.newComment = {};
					$scope.uploader.queue = [];
				});
			})
		} else {
			TodosService.createComment(newComment).$promise.then(function(res) {
				fetchComments();
				$scope.uploader.queue = [];
				$scope.newComment = {};
			});
		}
	}
	$scope.deleteComment = function(comment) {
		TodosService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		TodosService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;
		TodosService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
});
